<template class="z-index-3">
	<div
		class="modal fade"
		id="addPlanModal"
		tabindex="-1"
		data-bs-backdrop="true"
		aria-labelledby="addPlanModalLabel"
		aria-hidden="true"
	>
		<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content">
				<button
					type="button"
					class="btn-close btn-close-white"
					data-bs-dismiss="modal"
					aria-label="Close"
				></button>
				<div class="modal-body bg-dark text-white">
					<div class="display-5">{{ $t("add_plan_popup.subline") }}</div>
					<h1>{{ $t("add_plan_popup.headline") }}</h1>
					<p class="mb-2">{{ $t("add_plan_popup.bodytext") }}</p>

					<div class="d-flex align-items-center my-4">
						<div
							v-for="(plan, index) in getPlansWithoutBlockBPlan()"
							:key="plan.id"
							:class="`${index + 1 < plans.length ? 'pe-2' : ''} flex-fill`"
						>
							<div class="border border-secondary rounded p-2 rounded-2">
								<small class="d-block">{{ plan.plan_name }}</small>
								<small class="d-block text-secondary">
									{{
										selectedPlanId && selectedPlanId === plan.id && amount
											? plan.tera_hashes * amount
											: plan.tera_hashes
									}}
									TH/s
								</small>
								<small class="d-block text-secondary">
									{{
										selectedPlanId && selectedPlanId === plan.id && amount
											? getMonthlyPrice(plan.amount) * amount
											: getMonthlyPrice(plan.amount)
									}}
									EUR /
									<small>{{ $t("add_plan_popup.month_with_vat") }}</small>
								</small>
							</div>
						</div>
					</div>

					<Form @submit="orderPackage" :validation-schema="schemaOrder">
						<fieldset>
							<div class="mb-3">
								<label for="plan" class="form-label"
									><small>{{ $t("add_plan_popup.choose_package") }}</small></label
								>
								<div class="position-relative">
								<Field
									as="select"
									id="plan"
									name="plan"
									rows="5"
									class="form-control bg-transparent text-white position-relative z-1"
									label="test"
									v-model="selectedPlanId"
								>
									<option v-for="plan in getPlansWithoutBlockBPlan()" :key="plan.id" :value="plan.id">
										{{ plan.plan_name }}
									</option>
								</Field>
								<font-awesome-icon
									class="bg-dark text-white position-absolute select-icon"
									:icon="['fas', 'angle-down']"
									size="2xl"
								/>
							</div>
								<!-- <span class="dropdown-icon">v</span> -->
								<ErrorMessage name="plan" class="text-danger d-block mt-1" />
							</div>
							<div class="mb-3">
								<label for="packageSelect" class="form-label"
									><small>{{ $t("add_plan_popup.select_amount") }}*</small></label
								>
								<Field
									type="number"
									class="form-control bg-dark text-white"
									id="packageQuantity"
									name="packageQuantity"
									value="1"
									min="1"
									v-model="amount"
								/>
								<ErrorMessage name="packageQuantity" class="text-danger d-block mt-1" />
							</div>
							<div class="mb-3">
								<label for="other" class="form-label"
									><small>{{ $t("add_plan_popup.textarea_additional") }}</small></label
								>
								<Field
									as="textarea"
									type="text"
									id="other"
									name="other"
									rows="5"
									class="form-control bg-dark text-white"
								/>
							</div>
							<button class="btn btn-primary mt-4" type="submit">
								{{ $t("confirm") }}
							</button>
						</fieldset>
						<div
							class="state position-absolute start-0 top-0 end-0 bottom-0 d-flex justify-content-center align-items-center bg-dark"
						>
							<div class="loading text-center position-absolute">
								<div class="icon display-1 mb-3">
									<font-awesome-icon
										class="bg-dark text-white"
										:icon="['fas', 'circle-notch']"
										size="2xl"
										spin
										spin-reverse
									/>
								</div>
							</div>
							<div class="success-message text-center position-absolute">
								<div class="icon display-1 mb-3">
									<font-awesome-icon
										class="text-success bg-dark"
										:icon="['fas', 'circle-check']"
										size="2xl"
									/>
								</div>
								<div class="message">
									{{ $t("request.success_request") }}
								</div>
							</div>
							<div class="failure-message text-center position-absolute">
								<div class="icon display-1 mb-3">
									<font-awesome-icon
										class="text-danger bg-dark"
										:icon="['fas', 'circle-xmark']"
										size="2xl"
									/>
								</div>
								<div class="message">
									{{ $t("request.error") }}
								</div>
							</div>
						</div>
					</Form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import { useUserStore } from "@/js/user.js";
import { getIntervalToDisplay } from "@/js/common.js";
import { ref } from "vue";

export default {
	components: {
		Form,
		Field,
		ErrorMessage
	},
	data() {
		return {
			schemaOrder: ref(null),
			plans: [],
			selectedPlanId: null,
			amount: 1
		};
	},
	methods: {
		getPlansWithoutBlockBPlan() {
			// TODO: Fix in backend by turning active = false if possible
			return this.plans?.filter(plan => 
				plan.plan_name != "PlanB - 2TH" && 
				plan.plan_name != "PlanB 1 Year - 2TH"
			);
		},
		async orderPackage(values, eventHandler) {
			let userStore = useUserStore();
			eventHandler.evt.target.classList.add("submitted");

			let body = "";

			const selectedPlan = this.plans.find(row => row.id === values["plan"]);
			if (selectedPlan) body += "Paket: " + selectedPlan.plan_name;
			if (values["packageQuantity"]) body += "\nMenge: " + values["packageQuantity"];

			if (values["other"]) body += "\nBemerkung: " + values["other"];

			let orderData = {
				body: body,
				subject: "New package order"
			};

			userStore
				.sendFormSupport(orderData)
				.then(response => {
					if (response == 204) {
						eventHandler.evt.target.classList.add("success");
					} else eventHandler.evt.target.classList.add("failure");

					setTimeout(() => {
						eventHandler.evt.target.classList.remove("submitted");
						eventHandler.evt.target.classList.remove("success");
						eventHandler.evt.target.classList.remove("failure");
					}, 4000);
				})
				.catch(error => {
					console.log(error);
				});
		},
		async getPlans() {
			let userStore = useUserStore();

			userStore
				.getPlans()
				.then(data => {
					if (data) {
						this.plans = data;
						this.selectedPlanId = data[0].id;
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		getIntervalToDisplay(days) {
			return getIntervalToDisplay(days);
		},
		getMonthlyPrice(yearlyAmount) {
			return Number((yearlyAmount / 12).toFixed(2));
		}
	},
	beforeMount() {
		this.schemaOrder = yup.object({
			packageQuantity: yup
				.number()
				.typeError(this.$t("validation.number"))
				.min(1, this.$t("validation.number_above_0"))
				.max(9999, this.$t("validation.number_less_9999"))
				.required(this.$t("validation.required")),
			plan: yup
				.number()
				.typeError(this.$t("validation.number"))
				.min(1, this.$t("validation.number_above_0"))
				.required(this.$t("validation.required"))
		});

		this.getPlans();
	},
	mounted() {}
};
</script>

<style>
.select-icon {
	top: 10px;
    font-size: 21px;
    right: 11px;
}
</style>